@import "definitions";

.situation_editor {
    .edit_view {
        max-height: calc(100vh - 15em);
        display:flex;
        flex-direction: row;
    }
}

.situation_editor, .situation_tab_editor {
    .edit_view, .situation_tab_editor_inner {
        >div {
            overflow:auto;
            min-width:33%;
            margin-top: $mar;
            border: $border;

            &:first-child {
                margin-right: $mar;                  
            }
        }
    }
    
    .situation_available, .situation_selected, .situation_tab_editor_available, .situation_tab_editor_used {
        table {
            border-collapse: separate;
            border-spacing: $mar_tiny;
            width: 100%;
        }
    }

    .situation_available, .situation_tab_editor_available {
        table td {
            & > a {
                display: block;
                background-color: #eee;
                padding: $pad_small;

                &:hover {
                    background-color: #ddd;
                }

                &.ref_props {
                    margin: .5em 0 0 1em;
                }
            }
            
            ul li > a {
                display: block;
                background-color: #eee;
                padding: $pad_small;
                margin-bottom: $mar_tiny;

                &:hover {
                    background-color: #ddd;
                }
            }
        }
    }

    .situation_selected, .situation_tab_editor_used {
        table td:first-child .wrapper > a {
            display: block;
            background-color: #eee;
            padding: $pad_small;

            &:hover {
                background-color: #ddd;
            }
        }
    } 
}

.situation_tab_editor {
    margin-top: $mar_big;

    > strong {
        font-size: 1.2em;
    }

    .situation_tab_editor_inner {
        display:flex;
        max-height: 20em;

        &>div {
            min-width:25vw;
        }
    }
}


.situation_layout_editor {
    &_layout {
        display:flex;
        margin-bottom: $mar;
    }

    .all_available {        
        .property {
            margin:.2em 0;
            border: solid #aaa 1px;
            padding:.2em;
            cursor: move;
        }

        .children {
            >div {
                display:none;
                margin-left:.5em;
            }
        }
    }
    
    .situation_available {
        border:solid #aaa 1px;
        max-height:90vh;
        overflow:auto;
        width:25%;
    }

    .situation_grid {
        max-height:90vh;
        overflow:auto;
        width:100%;
        border:solid #aaa 1px;
        margin-left:1em;

        .tab_content {
            box-sizing:border-box;
            width:100%;
            height:100%;
            border:solid #aaa 1px;
            padding:.5em;
            background-color:lightgreen;
            height:100%;
        }

        .tab_col {
            box-sizing:border-box;
            width:100%;
            height:100%;
            border:solid #aaa 1px;
            padding:.5em;
            background-color:lightsalmon;
        }

        .tab_group {
            box-sizing:border-box;
            width:100%;
            border:solid #aaa 1px;
            padding:.5em;
            background-color:lightskyblue;
            padding-bottom:3em;
        }

        .columns {
            display:flex;
            flex-direction: row;
            padding:.5em;
            background-color:lightskyblue;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;
            }
        }

        .group {
            //display:flex;
            //flex-direction: row;
            padding:.5em;
            background-color:lightskyblue;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;
            }
        }
        
        .radio_group {
            padding:.5em;
            background-color:lightpink;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;
            }
        }
        
        .checkbox_group {
            padding:.5em;
            background-color:pink;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;
            }
        }

        .boolean_group {
            padding:.5em;
            background-color:plum;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;
            }
        }

        .property {
            padding:.2em;
            box-sizing:border-box;
            width:100%;
            border:solid #aaa 1px;
            padding:.5em;
            background-color:white;
        }

        .placeholder {
            width:100%;
            min-height:.5em;
            background-color: lightgoldenrodyellow;        
            &.last {
                min-height:3em;
            } 
            &.active {
                min-height:3em;
                background-color: orange;        
            }        
        }

        .table_row {
            display:flex;
            flex-direction: row;
            padding:.5em;
            background-color:lightskyblue;

            >div {
                padding:.5em;
                flex:1;
                border:solid #aaa 1px;
                background-color:lightgray;

                &.row_number {
                    flex-grow:0;
                }
            }
        }
    }
}