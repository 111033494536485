@import "utils.scss";

/*Allgemein*/
$full_bg: #efefef;                                          // gesamter Hintergrund
$base_font_color: #444;                                     // standard Schriftfarbe
$font_size: 0.75em;                                         // Schriftgröße
$font_size_heading: 1.4em;                                  // Überschriftsgröße
$font_size_big_icon: 1.2em;                                 // größere Icon 
$main_box_shadow: 1px 1px 2px #aaaaaa;                      // umschließendes Div von Objekten - Schlagschatten
$font_family: notosans, Arial, Helvetica, sans-serif;
$font_family_bold: notosans_bold, Arial, Helvetica, sans-serif;

/*Farben*/
$highlight_color: #da542c;                                  // Farbe zum hervorheben (z.B. wichtige Button und Hovereffekte)
$highlight_font_color: #fff;                                // Schriftfarbe für Hervorgehobenes
$highlight_color_hover: #000;                               // Hover - Farbe für Hervorgehobenes
$highlight_font_color_hover: #fff;                          // Hover Schriftfarbe für Hervorgehobenes
$theme_color: #A8A5A1;                                      // Farbe für Flächen (z.B. Button und Hintergrund 2.Navigation)
$theme_font_color: #fff;                                    // Schriftfarbe für Flächen 
$theme_color_light: #efefef;                                // hellere Farbe für Flächen (z.B. Hintergrund Seitenleiste)
$theme_font_color_light: $base_font_color;                  // Schriftfarbe für hellere Flächen
$main_bg: #fff;                                             // umschließendes Div von Objekten - Hintergrundfarbe

/*Color Coding Farben*/
$cc-colors: (
    green: #A2FAA8,
    darkgreen: #75d17b,
    red: #FF9E9E,
    light_red: #F6DBD2,
    orange: #FFB87D,
    yellow: #FFFFA2,
    blue: #ADC3FF,
    dark: #b0b0b0,
);

/*Linien / Rahmen*/
$border_color: #ddd;                                        // Farbe für Linien
$border_light: 1px solid #fff;                              // heller Rahmen
$border: 1px solid $border_color;                           // standard Rahmen
$border_bright: 1px solid #eee;                           // standard Rahmen
$border_dashed: dashed $border_color 2px;                   // gepunkteter Rahmen

/*Abstände*/            
$pad: 1em;              
$pad_tiny: 0.2em;
$pad_small: 0.5em;
$pad_medium: 0.75em;
$pad_big: 2.5em;

$mar: 1em;
$mar_tiny: 0.2em;
$mar_small: 0.5em;
$mar_medium: 0.75em;
$mar_big: 2.5em;

/*Icon*/

$big_icon_size: 14px;