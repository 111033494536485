/* 
 * Duff Framework
 * ©Selltec Communications GmbH
 */

@import "../../libs/font-awesome/4.7.0/scss/_variables.scss";
@import "../../libs/font-awesome/4.7.0/scss/_mixins.scss";

@mixin sl-icon($content, $width:0) {
  @include fa-icon();  
  &:before { 
      content: $content;
      @if $width>0 {
        display: inline-block;
        width: $width;
      }
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
